<!-- TOPBAR - small devices only -->
<nav class="nav-topbar d-md-none">
  <div class="d-flex align-items-center gap-2">
    <button mat-fab class="no--elevation fab--tertiary" color="accent" (click)="toggleOverlay.emit()">
      @if (overlayOpen()) {
        <mat-icon>close</mat-icon>
      } @else {
        <mat-icon>menu</mat-icon>
      }
    </button>
    @if (user()?.selectedTenant && (user()?.selectedTenant?.thumbUrl || user()?.selectedTenant?.name)) {
      <a class="link-reset" [routerLink]="['/']">
        <div class="user-thumb">
          @if (user()?.selectedTenant?.thumbUrl) {
            <img [src]="user()?.selectedTenant?.thumbUrl" [alt]="user()?.selectedTenant?.name" />
          } @else {
            <div class="user-thumb__letters">
              <span>{{ user()?.selectedTenant?.name }}</span>
            </div>
          }
        </div>
      </a>
    }
  </div>

  <button class="me-2 text-on-surface" mat-icon-button (click)="triggerSearch.emit()">
    <mat-icon>search</mat-icon>
  </button>
</nav>

<div class="nav-sidebar">
  <!-- RAIL - Bigger devices only -->
  <nav class="nav-rail px-2 pt-3 d-none d-md-flex" [class.has--sidebar]="activeMenuItem()?.menuItems?.length">
    @if (user()?.selectedTenant && (user()?.selectedTenant?.thumbUrl || user()?.selectedTenant?.name)) {
      <a class="link-reset d-block pb-3 px-2" [routerLink]="['/']">
        <div class="user-thumb">
          @if (user()?.selectedTenant?.thumbUrl) {
            <img [src]="user()?.selectedTenant?.thumbUrl" [alt]="user()?.selectedTenant?.name" />
          } @else {
            <div class="user-thumb__letters">
              <span>{{ user()?.selectedTenant?.name }}</span>
            </div>
          }
        </div>
      </a>
    }

    @if (user(); as user) {
      <button
        mat-fab
        class="no--elevation fab--tertiary mx-auto"
        (click)="triggerSearch.emit()"
        color="accent"
        [matTooltip]="('navigation:search' | fromDictionary) + shortcutSearch()"
        [matTooltipPosition]="'right'"
        [matTooltipClass]="'tooltip-primary'"
      >
        <mat-icon>search</mat-icon>
      </button>

      <ul class="side-rail-list d-flex flex-column gap-2 my-4">
        @for (item of menu(); track item; let last = $last) {
          <li>
            @if (!item.menuItems.length) {
              <a
                class="tab-button"
                [routerLink]="item.routerLink"
                routerLinkActive
                #rla="routerLinkActive"
                (click)="secondaryNavOpen.set(null); closeOverlay.emit(); activateMenuItem(item.guid)"
                [class.is--active]="item.guid === activeMenuGuid()"
              >
                <mat-icon class="tab-button__icon">{{ item.icon || 'folder' }}</mat-icon>
                <span class="tab-button__label">{{ item.title }}</span>
              </a>
            } @else {
              <button class="tab-button" [class.is--active]="item.guid === activeMenuGuid()" (click)="activateMenuItem(item.guid)">
                <mat-icon class="tab-button__icon">
                  {{ item.icon }}
                </mat-icon>
                <span class="tab-button__label">{{ item.title }}</span>
              </button>
            }
          </li>
        }
      </ul>

      <app-side-nav-meta-menu
        class="d-block mt-auto mb-1"
        [cssClasses]="'d-flex flex-column gap-1 text-center'"
        [user]="user"
        [theme]="theme()"
        [hasTenants]="hasTenants()"
        (triggerTenantSwitcher)="triggerTenantSwitcher.emit()"
        (closeOverlay)="closeOverlay.emit(); secondaryNavOpen.set(null)"
      ></app-side-nav-meta-menu>
    }
  </nav>

  <!-- Backdrop of sidebar on mobile -->
  @if (overlayOpen()) {
    <div
      class="nav-sidebar__page-overlay"
      (click)="secondaryNavOpen() ? secondaryNavOpen.set(null) : closeOverlay.emit()"
      [@fadeInOut]
    ></div>
  }

  <!-- Nav niveau 1 - small devices only -->
  @if (overlayOpen()) {
    <mat-nav-list class="px-2 side-nav-list" [@slideInOut]>
      <button class="nav-back-button mb-3 text-on-surface" mat-list-item (click)="closeOverlay.emit()">
        <mat-icon matListItemIcon>menu_open</mat-icon>
        <!--<span matListItemTitle>Sluit menu</span>-->
      </button>

      @for (item of menu(); track item.guid) {
        @if (!item.menuItems.length) {
          <a
            mat-list-item
            [routerLink]="item.routerLink"
            routerLinkActive
            #rla="routerLinkActive"
            [activated]="rla.isActive"
            (click)="secondaryNavOpen.set(null); closeOverlay.emit()"
            class="text-on-surface"
          >
            <mat-icon matListItemIcon>{{ item.icon || 'folder' }}</mat-icon>
            <span matListItemTitle>{{ item.title }}</span>
          </a>
        } @else {
          <button
            mat-list-item
            [class.mdc-list-item--activated]="item.guid === activeMenuGuid()"
            (click)="secondaryNavOpen.set(item.guid); activateMenuItem(item.guid)"
            class="text-on-surface"
          >
            <mat-icon matListItemIcon>
              {{ item.icon }}
            </mat-icon>
            <span matListItemTitle>
              <span>{{ item.title }}</span>
              <mat-icon class="mat-list-item--action-icon">arrow_forward</mat-icon>
            </span>
          </button>
        }
      }

      @if (user()) {
        <app-side-nav-meta-menu
          class="d-block mt-auto mobile-meta-menu"
          [user]="user()!"
          [theme]="theme()"
          [hasTenants]="hasTenants()"
          (triggerTenantSwitcher)="triggerTenantSwitcher.emit()"
          (closeOverlay)="closeOverlay.emit(); secondaryNavOpen.set(null)"
        ></app-side-nav-meta-menu>
      }
    </mat-nav-list>
  }

  <!-- Nav niveau 2 -->
  @if (activeMenuItem()?.menuItems?.length) {
    <mat-nav-list
      class="side-nav-list side-nav-list--overlay"
      [class.is--closed]="!overlayOpen()"
      [class.is--test]="activeMenuItem()?.menuItems?.length"
      [@slideInOut]="secondaryNavOpen() ? 'open' : 'closed'"
      [class.mat-elevation-z8]="secondaryNavOpen()"
    >
      <div class="px-2">
        <button class="nav-back-button mb-3 d-md-none text-on-surface" mat-list-item (click)="secondaryNavOpen.set(null)">
          <mat-icon matListItemIcon>arrow_backwards</mat-icon>
        </button>

        <button
          class="nav-back-button mb-3 d-none d-md-inline-block text-on-surface"
          mat-list-item
          (click)="secondaryNavOpen.set(null); sidebarClosed.set(true)"
        >
          <mat-icon matListItemIcon>menu_open</mat-icon>
        </button>

        <span matSubheader class="w-100 d-inline-block mat-title-large">{{ activeMenuItem()?.title }}</span>
        @for (item of activeMenuItem()?.menuItems; track item.guid) {
          @if (item.menuItems.length) {
            @if ($index > 0) {
              <mat-divider></mat-divider>
            }
            <span matSubheader class="w-100 d-inline-block text-outline">
              {{ item.title }}
            </span>
            @for (i of item.menuItems; track i.guid) {
              <a
                mat-list-item
                [routerLink]="i.routerLink"
                routerLinkActive
                #rla="routerLinkActive"
                [activated]="rla.isActive"
                (click)="closeOverlay.emit()"
              >
                <mat-icon matListItemIcon>{{ i.icon || 'folder' }}</mat-icon>
                <span matListItemTitle>{{ i.title }}</span>
              </a>
            }
          } @else {
            <a
              mat-list-item
              [routerLink]="item.routerLink"
              routerLinkActive
              #rla="routerLinkActive"
              [activated]="rla.isActive"
              (click)="closeOverlay.emit()"
            >
              <mat-icon matListItemIcon>{{ item.icon || 'folder' }}</mat-icon>
              <span matListItemTitle>{{ item.title }}</span>
            </a>
          }
        }
      </div>
    </mat-nav-list>
  }
</div>
